<template>

    <v-dialog  v-model="drawer" class="ma-0"   @click:outside="close" :style="{height:$vuetify.breakpoint.height+'px !important'}" :width="$vuetify.breakpoint.width+'px'">
      <transition name="bounce">
        <v-card tile v-if="drawer" class="ma-0 pa-0" :loading="loadingPDF" style="position:absolute !important;right: 0;top:0;" width="80%"  :height="$vuetify.breakpoint.height+'px'">
          <v-container class="ma-0 pa-0" >
            <v-row no-gutters >
              <v-col cols="4" :style="{height:$vuetify.breakpoint.height+'px !important',backgroundColor:'lightgrey'}">
                <v-card tile elevation="0">
                  <v-toolbar class="py-0" elevation="1" dark color="primary" height="48" tile>
                    <v-icon @click="close">
                      mdi-close
                    </v-icon>
                    <v-spacer/>
                    <v-toolbar-title class="text-center">
                      {{$t('settings.lang_pdfOptions')}}
                    </v-toolbar-title>
                    <v-spacer/>
                  </v-toolbar>
                  <v-card-text class="mt-12">
                    <v-row class="mt-12">
                      <v-col cols="12">
                        <v-checkbox v-model="noBorderColor" :label="$t('generic.lang_printWithoutBorderColor')">
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <v-btn color="primary" elevation="1" block class="ma-0" small outlined dark depressed @click="getPDF">
                          {{$t('generic.lang_apply')}}
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-btn color="success"  elevation="1" block class="ma-0" small @click="downloadPDF" outlined dark depressed :disabled="!iframePDFContent">
                          <v-icon>mdi-download</v-icon>{{$t('generic.lang_download')}}
                        </v-btn>
                      </v-col>

                    </v-row>
                  </v-card-text>
                </v-card>

              </v-col>
              <v-col cols="8" :style="{height:$vuetify.breakpoint.height+'px !important'}" v-if="iframePDFContent">
                <iframe :src="iframePDFContent" v-if="iframePDFContent&&iframePDFContent.length > 0" data-role="pdf" width="100%" style="border: 0;" height="100%"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </transition>
    </v-dialog>

</template>

<script>
import FileSaver from 'file-saver';
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
export default {
  name: "PDFPreviewDialog",
  props:{
    drawer:Boolean,
    api:String,
    data:{
      required:true,
    }
  },
  data(){
    return{
      ENDPOINTS,
      loadingPDF:false,
      iframePDFContent:null,
      responseData:null,
      noBorderColor:false,
    }
  },
  watch:{
    drawer(val){
      if(val){
        this.getPDF()

      }
    }
  },
  computed:{

  },
  methods:{
    close(){
      this.$emit('close')
    },
    getPDF() {
      this.loadingPDF=true

      this.axios.post(this.api, {
            labels:this.data,
            noBorderColor:(this.noBorderColor)?1:0,
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {


        //GENERATE PDF CONTENT FOR iFRAME
        this.responseData=res.data
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingPDF=false
      })
    },
    downloadPDF(){
      if(this.iframePDFContent && this.iframePDFContent.length>0&& this.responseData){
        FileSaver.saveAs(new Blob([this.responseData], {
          type: "application/pdf"
        }), "labels.pdf");
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .6s;
  animation-timing-function: ease-in-out;
}
.bounce-leave-active {
  animation: bounce-in .6s reverse;
  animation-timing-function: ease-in-out;
}
@keyframes bounce-in {
  0% {
    transform: translateX(1500px);

  }
  100% {
    transform: translateX(0);
  }
}
</style>