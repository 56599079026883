<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-if="!loading" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="mb-3 card">
      <v-tabs background-color="transparent" v-model="tab">
        <v-tab key="baseData">
          {{ $t('customers.lang_kundenprofil') }}
        </v-tab>

        <v-tab key="finance" v-if="this.$store.getters['permissions/checkPermission']('customerFinance')">{{ $t('generic.lang_finance') }}</v-tab>

        <v-tab key="customersInvoices" v-if="this.$store.getters['permissions/checkPermission']('customerInvoices')">{{ $t('customers.lang_displayCustomerInvoices') }}</v-tab>


        <v-tabs-items touchless v-model="tab">
          <v-tab-item key="baseData">
            <v-container fluid>
              <v-layout wrap>
                <v-flex md5 xs12>
                  <v-text-field style="margin-top: 40px;" dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_company')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="company"
                  >
                  </v-text-field>
                  <!-- customer department -->
                  <v-select dense :items="departments"
                            :label="$t('customers.lang_customerDepartments')"
                            item-text="name"
                            item-value="id"
                            outlined
                            v-model="department"
                            :loading="departments_loader"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>arrow_drop_down</v-icon>
                    </template>


                    <template v-slot:append>
                      <v-menu
                          :close-on-content-click="false"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          :nudge-width="200"
                          id="menu_create"
                          max-width="304"
                          offset-y
                          transition="slide-x-transition"
                          v-model="create_department_menu"
                          z-index="10"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn @click="showDepartmentMenu" color="primary"
                                 fab
                                 id="icon-wiregroup-add" small
                                 v-on="on">
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <v-card outlined>
                          <v-row align="center"
                                 class="dropdown-menu-header"
                                 justify="center">
                            <div class="dropdown-menu-header-inner bg-primary"
                            >
                              <div class="menu-header-image dd-header-bg-1"></div>
                              <div class="menu-header-content text-center pa-3 ma-0"
                              >
                                <div class="text-center mx-3"><h5
                                    class="menu-header-title ">
                                  {{ $t('customers.lang_customerDepartmentsAdd') }}</h5>
                                  <span class="mx-auto text-center mx-3">
                                              {{ $t('customers.lang_addDepartmentText') }}
                                            </span>
                                </div>
                              </div>
                            </div>
                          </v-row>
                          <div class="scroll-area-xl pa-2">
                            <v-form class="ma-0 pa-0"
                                    lazy-validation
                                    onsubmit="return false"
                                    ref="departmentForm">

                              <v-row justify-md="center">
                                <v-col cols="12">
                                  <v-text-field v-model="new_department.departmentName"
                                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                :label="$t('customers.lang_departmentName')"
                                                autocomplete="off"
                                                :rules="[v=>!!v && v.length>0 || $t('generic.lang_requiredField')]"
                                                outlined
                                                required
                                                @focus="showTouchKeyboard"
                                  ></v-text-field>
                                </v-col>

                              </v-row>

                              <v-divider class="pa-0 ma-0"/>
                              <div class="text-center">
                                <button @click="create_department_menu = false"
                                        class="btn btn-danger ma-2">
                                  {{ $t('generic.lang_cancel') }}
                                </button>

                                <v-btn color="primary"
                                       :loading="loadingCreateDepartment"
                                       @click="createDepartment"
                                       :disabled="new_department.name===''||new_department.name===null">
                                  {{ $t('generic.lang_create') }}
                                </v-btn>
                              </div>
                            </v-form>
                          </div>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-select>

                  <v-select dense :items="salutations"
                            :label="$t('customers.lang_cust_anrede')"
                            item-text="name"
                            item-value="id"
                            outlined
                            v-model="salutation"
                  ></v-select>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('customers.lang_cust_prename')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="preName"
                  >
                  </v-text-field>
                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('customers.lang_cust_name')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="lastName"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_street')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="streetName"
                  >
                  </v-text-field>
                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                :label="$t('localbee.lang_lbRestaurantStreetNo')"
                                outlined
                                required
                                v-model="streetNumber"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('customers.lang_cust_zip')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="zip"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('settings.lang_city')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                v-model="city"
                  >
                  </v-text-field>
                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_country')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                v-model="country"
                  >
                  </v-text-field>

                  <v-textarea dense :label="$t('accounting.lang_InvoiceNotes')"
                              name="termsOfPayment"
                              outlined
                              v-model="receiptNotes"
                              value=""
                  ></v-textarea>
                </v-flex>

                <v-flex md1 xs0></v-flex>

                <v-flex md6 xs12>
                  <BirthdayPicker dense :label="$t('customers.lang_cust_birth')" v-model="birthday"/>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_emailAdresse')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="email"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_Website')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="website"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('customers.lang_cust_tel_priv')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="tel_private"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('customers.lang_cust_mobil_priv')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="mobil_private"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('customers.lang_cust_tel_business')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="tel_business"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('customers.lang_cust_mobil_business')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="mobil_business"
                  >
                  </v-text-field>

                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_fax')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="fax"
                  >
                  </v-text-field>
                  <v-text-field dense :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_room')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                disabled
                                v-model="bookedRoomNo"
                  >
                  </v-text-field>
                  <v-textarea dense :label="$t('generic.lang_internalNotes')"
                              name="termsOfPayment"
                              outlined
                              v-model="notes"
                              value=""
                  ></v-textarea>

                </v-flex>
              </v-layout>
              <v-row>
                <v-col cols="12" v-if="false">
                  <MapLocationPicker v-model="position" :zoom="11"/>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item key="finance" v-if="this.$store.getters['permissions/checkPermission']('customerFinance')">
            <v-container fluid>
              <v-layout wrap>
                <v-flex md5 xs12>
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('accounting.lang_VATID')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="vatID"
                  >
                  </v-text-field>

                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('accounting.lang_debitorKonto')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                v-model="debitorAccount"
                  >
                  </v-text-field>
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                label="IBAN"
                                outlined
                                required
                                v-model="iban"
                  >
                  </v-text-field>
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('settings.lang_accountOwner')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="accountOwner"
                  >
                  </v-text-field>

                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('accounting.lang_MandateReference')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                v-model="mandatRef"
                  >
                  </v-text-field>
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('accounting.lang_paymentTermsInDays')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                v-model="paymentDueTerms"
                  >
                  </v-text-field>

                  <v-radio-group
                      v-model="payment_type"
                      column
                      :label="$t('customers.lang_preferedInvoicePayment')"
                      class="d-flex"
                      hide-details
                  >
                    <v-radio
                        :label="$t('accounting.lang_bankTransfer')"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        :label="$t('accounting.lang_directDebit')"
                        :value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex md1 xs0>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('accounting.lang_vendorAccount')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="kreditorAcc"
                  >
                  </v-text-field>

                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                label="BIC"
                                outlined
                                required
                                v-model="bic"
                  >
                  </v-text-field>

                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_bankname')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="bankName"
                  >
                  </v-text-field>

                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('accounting.lang_CreditorID')"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                outlined
                                required
                                v-model="creditorID"
                  >
                  </v-text-field>
                  <v-textarea :label="$t('accounting.lang_termsOfPayment')"
                              name="termsOfPayment"
                              @focus="showTouchKeyboard"
                              outlined
                              v-model="termsOfPayment"
                              value=""
                  ></v-textarea>


                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item key="customersInvoices" v-if="this.$store.getters['permissions/checkPermission']('customerInvoices')">
            <v-form ref="form" lazy-validation>
              <v-row class="pa-3 pt-5">

                <v-col cols="12" sm="6">
                  <v-text-field v-model="startDateTime" :disabled="this.dates_loading"
                                :label="$t('accounting.lang_dailyReportStart')"
                                :loading="this.dates_loading"
                                :rules="[v => !!v]" dense outlined
                                type="date"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="endDateTime" :disabled="this.dates_loading"
                                :label="$t('accounting.lang_dailyReportEnd')"
                                :loading="this.dates_loading"
                                :rules="[v => !!v]" dense outlined
                                type="date"/>
                </v-col>

              </v-row>
            </v-form>
            <Datatable :additionalGetParams="additionalParams"
                       :api-endpoint="ENDPOINTS.DATATABLES.CUSTOMERS.SEARCHINVOICES"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       :reset-search="true"
                       @displayEntry="displayEntry"
                       excel-file-name="Verkäufe"
                       ref="salesTable"
                       show-display-buttons
                       v-if="showTable"
                       pdf-f-ile-name="customer_invoices"
                       show-pdf
            />
          </v-tab-item>

        </v-tabs-items>
      </v-tabs>
      <v-container fluid>
        <v-layout>
          <v-flex class="text-right">
            <v-btn @click="goBacktoCustomers" color="error" text>{{ $t('generic.lang_prev') }}</v-btn>
            <v-btn @click="deleteData" color="error"
                   v-if="this.$store.getters['permissions/checkPermission']('delete_cust')">{{
                $t('generic.lang_delete')
              }}
            </v-btn>
            <v-btn :disabled="(preName && preName.length < 1) || (lastName && lastName.length < 1 )|| id < 1"
                   @click="editData"
                   color="success">{{
                $t('generic.lang_edit')
              }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '../../config'
import {Events} from "../../plugins/events";
import BirthdayPicker from "../common/birthdaypicker";
import mixin from "../../mixins/KeyboardMixIns";
import Datatable from "../datatable/Datatable";
import AccountComponent from "./account/AccountComponent";


export default {
  components: {
    AccountComponent,
    BirthdayPicker,
    Datatable
  },
  mixins: [mixin],
  props: {
    customerName: String
  },
  data() {
    return {
      ENDPOINTS,
      new_department: {
        departmentName: ""
      },
      loadingCreateDepartment: false,
      create_department_menu: false,
      startDateTime: "",
      endDateTime: "",
      dates_loading: false,
      departments_loader: false,
      datatableHeaders: [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "uuid",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "id", sort: "desc"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerid"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_totalsolditems'), value: "saleItems"},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
        {text: this.$t('accounting.lang_paymenthod'), value: "paymentType"},
        {text: this.$t('generic.lang_TableNo'), value: "tableNo"},
        {text: this.$t('generic.lang_parMaison'), value: "parMaison"},
        {text: this.$t('generic.lang_cashierID'), value: "cashierID"},
        {text: this.$t('generic.lang_receipttype'), value: "type"},
      ],
      excelColumns: [
        {label: this.$t('generic.lang_id'), field: "id"},
        {label: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_seller_id'), field: "sellerid"},
        {label: this.$t('generic.lang_customer'), field: "cust"},
        {label: this.$t('erp.lang_totalsolditems'), field: "saleItems"},
        {label: this.$t('erp.lang_ware_total'), field: "total"},
        {label: this.$t('accounting.lang_paymenthod'), field: "paymentType"},
        {label: this.$t('generic.lang_TableNo'), field: "tableNo"},
        {label: this.$t('generic.lang_parMaison'), field: "parMaison"},
        {label: this.$t('generic.lang_cashierID'), field: "cashierID"},
        {label: this.$t('generic.lang_receipttype'), field: "type"},
      ],
      salutations: [],
      departments: [],
      additionalParams: ["customerID"],
      custGroups: [],
      payment_type: 2,
      select: '',
      id: null,
      loading: false,
      tab: 0,
      showTable: true,
      company: "",
      salutation: "",
      department: "",
      preName: "",
      lastName: "",
      streetName: "",
      streetNumber: "",
      zip: "",
      city: "",
      country: "",
      receiptNotes: "",
      birthday: "",
      email: "",
      website: "",
      tel_private: "",
      mobil_private: "",
      tel_business: "",
      mobil_business: "",
      fax: "",
      custGroupChooser: "",
      notes: "",
      vatID: "",
      debitorAccount: "",
      iban: "",
      accountOwner: "",
      mandatRef: "",
      paymentDueTerms: "",
      kreditorAcc: "",
      bic: "",
      bankName: "",
      creditorID: "",
      termsOfPayment: "",
      customerCardID: "",
      image: null,
      bookedRoomNo: "",
      loadingImage: false,
      position: [],
    }
  },

  watch: {},

  methods: {
    loadDepartments() {
      this.departments_loader = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.GET_ALL).then((res) => {
        if (Array.isArray(res.data.results))
          this.departments = res.data.results.map((dp) => ({
            id: dp.id,
            name: dp.departmentName
          }))
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.departments_loader = false;
      })
    },
    createDepartment() {
      if (!this.$refs.departmentForm.validate()) return;

      this.loadingCreateDepartment = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.CREATE, this.new_department).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('customers.lang_departmentAdded'),
            color: "success"
          });
          this.$refs.departmentForm.reset();
          this.create_department_menu = false;
          this.loadDepartments();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingCreateDepartment = false
      });
    },
    showDepartmentMenu() {
      this.create_department_menu = true;
    },
    displayEntry(entry) {
      this.uuid = entry.uuid;
      this.text = entry.name;
      this.$router.push({name: 'accounting.DisplayInvoice', params: {id: this.uuid}})
    },
    goBacktoCustomers: function () {
      this.$router.push('/customer/customers');
    },
    editData: function () {
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.UPDATE, {
        customerID: this.id,
        company: this.company,
        bookedRoomNo: this.bookedRoomNo,
        salutation: this.salutation,
        department: this.department,
        first_name: this.preName,
        last_name: this.lastName,
        street: this.streetName,
        street_no: this.streetNumber,
        zip: this.zip,
        city: this.city,
        country: this.country,
        receiptNotes: this.receiptNotes,
        birthday: this.birthday,
        email: this.email,
        website: this.website,
        tel_private: this.tel_private,
        mobil_private: this.mobil_private,
        tel_business: this.tel_business,
        mobil_business: this.mobil_business,
        fax: this.fax,
        customerGroup: this.custGroupChooser,
        notes: this.notes,
        VATID: this.vatID,
        custDebitorenNr: this.debitorAccount,
        IBAN: this.iban,
        KontoInhaber: this.accountOwner,
        Mandatsreferenznummer: this.mandatRef,
        paymentDeadlineDays: this.paymentDueTerms,
        custKreditorenNr: this.kreditorAcc,
        BIC: this.bic,
        BankName: this.bankName,
        glauebigerID: this.creditorID,
        custPaymentTerms: this.termsOfPayment,
        customerCardID: this.customerCardID,
        geoLoc: this.position,
        payment_type: this.payment_type
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('customers.lang_customerUpdated'),
            color: "success"
          });
          this.goBacktoCustomers();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    getData: function () {
      this.loading = true;

      this.id = parseInt(this.$parent.id);
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: this.id
      }).then((res) => {
        if (res.status === 200) {
          this.loading = false;
          res.data.customerGroups.forEach((row) => {
            this.custGroups.push({name: row[1], id: parseInt(row[0])});
          });

          res.data.salutations.forEach((row) => {
            this.salutations.push({name: row[1], id: parseInt(row[0])});
          });

          res.data.departments.forEach((row) => {
            this.departments.push({name: row[1], id: parseInt(row[0])});
          });

          this.company = res.data.customer.company;
          this.bookedRoomNo = res.data.customer.bookedRoomNo;
          this.salutation = res.data.customer.salutation;
          this.department = res.data.customer.department;
          this.preName = res.data.customer.first_name;
          this.lastName = res.data.customer.last_name;
          this.streetName = res.data.customer.street;
          this.streetNumber = res.data.customer.street_no;
          this.zip = res.data.customer.zip;
          this.city = res.data.customer.city;
          this.country = res.data.customer.country;
          this.receiptNotes = res.data.customer.receiptNotes;
          this.birthday = res.data.customer.birthday;
          this.email = res.data.customer.email;
          this.website = res.data.customer.website;
          this.tel_private = res.data.customer.tel_private;
          this.mobil_private = res.data.customer.mobil_private;
          this.tel_business = res.data.customer.tel_business;
          this.mobil_business = res.data.customer.mobil_business;
          this.fax = res.data.customer.fax;
          this.custGroupChooser = res.data.customer.customerGroup;
          this.notes = res.data.customer.notes;
          this.vatID = res.data.customer.VATID;
          this.debitorAccount = res.data.customer.custDebitorenNr;
          this.iban = res.data.customer.IBAN;
          this.accountOwner = res.data.customer.KontoInhaber;
          this.mandatRef = res.data.customer.Mandatsreferenznummer;
          this.paymentDueTerms = res.data.customer.paymentDeadlineDays;
          this.kreditorAcc = res.data.customer.custKreditorenNr;
          this.bic = res.data.customer.BIC;
          this.bankName = res.data.customer.BankName;
          this.creditorID = res.data.customer.glauebigerID;
          this.termsOfPayment = res.data.customer.custPaymentTerms;
          this.customerCardID = res.data.customer.customerCardID;
          this.image = res.data.customer.image;
          this.position = res.data.customer.geoLoc;
          this.payment_type = res.data.customer.payment_type;

          this.$emit('input', res.data.customer.first_name + " " + res.data.customer.last_name);
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    deleteData: function (idsToDelete = []) {
      this.$swal({
        title: this.$t('customer.lang_customerDeleteHead'),
        text: this.$t('customer.lang_customerDeleteBody'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.DELETE, {
            customerID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('customer.lang_customerDeleted'),
                color: "success"
              });

              this.resetData();
              this.goBacktoItemgroups();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    checkCustomerCardID() {
      if (this.customerCardID.includes("CC$"))
        this.customerCardID = this.customerCardID.split("CC$")[1];
    },
    imageFinished(image) {
      this.image = image;
    },
    deleteImage() {
      this.loadingImage = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.IMAGE.DELETE, {
        customerId: this.id
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_photoDeleted'),
          });

          this.image = null;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_photoCouldNotBeDeleted'),
        });
      }).finally(() => this.loadingImage = false)
    }
  }
  ,

  mounted() {
    this.getData();
    this.additionalParams[1] = this.id;
  }
  ,

}
</script>
